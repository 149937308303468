<template>
  <div>
    <!--  Visual Section -->
    <section
      ref="cover"
      class="section cover-section">
      <CoverTransition>
        <div class="section-bg global-bg">
          <div class="title-wrap">
            <h2 class="ani-text-up">GLOBAL CENTER</h2>
            <div class="title-desc ani-text-up" v-html="$t('content.center.Global.txt01')">
            </div>
          </div>
        </div>
      </CoverTransition>
    </section>
    <!--  // Visual Section -->
    <!-- 센터 제공 혜택 -->
    <section class="inner-section">
      <div class="inner-lg">
        <div class="title-wrap">
          <h3 class="kor" v-html="$t('content.center.Global.txt12')"></h3>
          <div class="title-desc" v-html="$t('content.center.Global.txt02')">
          </div>
        </div>
        <ul class="icon-card-list line-type ani-stagger">
          <li>
            <dl class="icon-card consulting">
              <dt>LOCALIZATION</dt>
              <dd v-html="$t('content.center.Global.txt03')">
              </dd>
            </dl>
          </li>
          <li>
            <dl class="icon-card communication">
              <dt>COMMUNITY</dt>
              <dd v-html="$t('content.center.Global.txt04')">
              </dd>
            </dl>
          </li>
          <li>
            <dl class="icon-card investment">
              <dt>INVESTMENT</dt>
              <dd v-html="$t('content.center.Global.txt05')">
              </dd>
            </dl>
          </li>
        </ul>
      </div>
    </section>
    <!-- // 센터 제공 혜택 -->
    <!-- DREAMPLUS GLOBAL CENTER -->
    <section class="inner-section">
      <div class="title-wrap">
        <h3>DREAMPLUS GLOBAL CENTER</h3>
        <div class="title-desc">
          {{$t('content.center.Global.txt06')}}
          <br />{{$t('content.center.Global.txt07')}} : <ExternalLink
                  to="mailto:contact@dreamplus.asia"
                >contact@dreamplus.asia</ExternalLink>
        </div>
      </div>
      <div class="global-map-area">
        <div class="global-map" data-aos="fade-up">
          <div class="marker-wrap sf">
            <span class="marker" data-aos="zoom-in" data-aos-delay="300"></span>
            <span class="branch-name">San Francisco Center</span>
          </div>
          <div class="marker-wrap fukuoka">
            <span class="marker" data-aos="zoom-in" data-aos-delay="300"></span>
            <span class="branch-name">Fukuoka Center</span>
          </div>
        </div>
      </div>
    </section>
    <!-- // DREAMPLUS GLOBAL CENTER -->

    <!-- GLOBAL BRANCH -->
    <section class="inner-section">
      <div class="inner-lg">
        <div class="branch-wrap">
          <ul class="branch-list">
            <li data-aos="fade-right">
              <div class="branch">
                <img
                  src="~@/assets/images/center/photo-san-francisco.jpg"
                  alt="san-francisco photo"
                />
                <div class="layer-over">
                  <div class="layer-logo" data-aos="fade-down" data-aos-delay="200">
                    <img
                      src="~@/assets/images/center/logo-san-francisco.png"
                      alt="Dreamplus San Francisco"
                    />
                  </div>
                  <div class="layer-text" data-aos="fade-up" data-aos-delay="500">
                    <p v-html="$t('content.center.Global.txt08')">
                    </p>
                    <p v-html="$t('content.center.Global.txt09')">
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li data-aos="fade-left">
              <div class="branch">
                <img src="~@/assets/images/center/photo-fukuoka.jpg" alt="fukuoka photo" />
                <div class="layer-over">
                  <div class="layer-logo" data-aos="fade-down" data-aos-delay="200">
                    <img src="~@/assets/images/center/logo-fukuoka.png" alt="Dreamplus Fukuoka" />
                  </div>
                  <div class="layer-text" data-aos="fade-up" data-aos-delay="500">
                    <p v-html="$t('content.center.Global.txt10')">
                    </p>
                    <p v-html="$t('content.center.Global.txt11')">
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- // GLOBAL -->
  </div>
</template>

<script>
import { mixin as mixinHelperUtils, mixinMotion } from '@/common/helperUtils';
import CoverTransition from '@/components/transition/CoverTransition.vue';
import ExternalLink from '@/components/base/ExternalLink.vue';

export default {
  name: 'Global',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {
    CoverTransition,
    ExternalLink,
  },
  data() {
    return {};
  },
};
</script>
